import React, { useEffect, useState } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import PageModelBase from '../Shared/Models/PageModelBase.interface';
import { GetCustomerPlanograms } from './MyPlanograms';
import PlanogramIcon from '../Shared/Icons/PlanogramIcon';
import ImagePlaceholderIcon from '../Shared/Icons/ImagePlaceholderIcon';
import { styled, theme } from '../Theme';
import SimpleProduct from '../../Features/PlanogramPage/Models/SimpleProduct.interface';
import KexLink from '../Shared/KexLink/KexLink';
import { media, pseudo } from '@glitz/core';
import { mediaQuery } from '../Theme/mediaQueries';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import CtaButton from '../Shared/CtaButton/CtaButton';
import Dots from '../Shared/Button/Dots';

type PropType = {
  showAllPlanograms?: boolean;
};

function MyPlanogramsComponent({ showAllPlanograms = true }: PropType) {
  const {
    translations: {
      'planogram/myPlanograms': myPlanogramsLabel,
      'planogram/seeAllPlanograms': seeAllPlanogramsLabel,
    },
    staticPages: { myPlanogramsPage },
    languageRoute,
    requestToken,
    placeholderImages: { planogramPlaceholder: planogramPlaceholderImg },
  } = useAppSettingsData();
  const { organizationId } = useUserStateData();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { channelId } = useCurrentPage<PageModelBase>();
  const url = `/api/${languageRoute}/planogram/GetMyPlanograms?organizationId=${organizationId}`;
  const [myPlanograms, setMyPlanograms] = useState<SimpleProduct[]>([]);

  useEffect(() => {
    organizationId &&
      GetCustomerPlanograms(
        channelId,
        requestToken,
        url,
        setIsLoading
      ).then(data =>
        !showAllPlanograms && !!data.length && data.length > 4
          ? setMyPlanograms(data.slice(0, 4))
          : setMyPlanograms(data)
      );
  }, [channelId]);

  return (
    <Wrapper>
      <Container>
        <HeadingSection>
          <PlanogramIcon />
          <Heading>{myPlanogramsLabel}</Heading>
        </HeadingSection>
        {isLoading ? (
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        ) : (
          <PlanogramGrid data-showbtn={!showAllPlanograms}>
            {!!myPlanograms.length &&
              myPlanograms.map(
                (planogram: SimpleProduct) =>
                  planogram.url && (
                    <KexLink key={planogram.articleNumber} href={planogram.url}>
                      <PlanogramCard>
                        {planogram.image ? (
                          <PlanogramImage
                            src={planogram.image.src}
                          ></PlanogramImage>
                        ) : (
                          <PlanogramImage
                            src={planogramPlaceholderImg?.src}
                          ></PlanogramImage>
                        )}
                        <PlanogramText>{planogram.name}</PlanogramText>
                        <PlanogramArticleNumber>
                          {planogram.articleNumber}
                        </PlanogramArticleNumber>
                      </PlanogramCard>
                    </KexLink>
                  )
              )}
          </PlanogramGrid>
        )}
        {!showAllPlanograms && !!myPlanograms.length && (
          <LinkWrapper>
            <StyledCtaButton asLink={true} href={myPlanogramsPage}>
              {seeAllPlanogramsLabel}
            </StyledCtaButton>
          </LinkWrapper>
        )}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div({
  padding: { x: '7.5px' },
  marginBottom: theme.spacing(10),
  ...media(mediaQuery.mediaMinLarge, {
    padding: { x: theme.spacing(10) },
    maxWidth: theme.screenMaxWidth,
    margin: { x: 'auto' },
  }),
});

const Container = styled.div({
  backgroundColor: theme.white,
  padding: { x: theme.spacing(6) },
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(10),
  borderRadius: '10px',
  ...media(mediaQuery.mediaMinLarge, {
    padding: { x: theme.spacing(11) },
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(16),
  }),
});

const DotsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: theme.spacing(9),
});

const HeadingSection = styled.div({
  display: 'flex',
  marginBottom: theme.spacing(7),
});

const StyledCtaButton = styled(CtaButton, {
  marginTop: theme.spacing(11),
  marginBottom: theme.spacing(6),
  ...media(mediaQuery.mediaMaxLarge, {
    margin: { x: 'auto' },
  }),
});

const LinkWrapper = styled.div({
  display: 'flex',
});

const Heading = styled.h3({
  fontSize: '22px',
  color: theme.black,
  marginLeft: theme.spacing(3),
  lineHeight: '26.4px',
});

const PlanogramGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
  gridColumnGap: theme.spacing(4),
  gridRowGap: theme.spacing(7),
  ...media(mediaQuery.mediaMinMedium, {
    gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
    ...pseudo([':nth-child(n)[data-showbtn="true"]'], {
      gridTemplateColumns: `repeat(4, minmax(0, 1fr))`,
    }),
  }),
  ...media(mediaQuery.mediaMinLarge, {
    gridTemplateColumns: `repeat(4, minmax(0, 1fr))`,
    gridColumnGap: theme.spacing(9),
    gridRowGap: theme.spacing(11),
  }),
});

const PlanogramCard = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  wordBreak: 'break-word',
  border: {
    xy: {
      style: 'solid',
      width: theme.tiny,
      color: theme.veryLightGray,
    },
  },
  borderRadius: '10px',
  paddingBottom: theme.spacing(6),
  paddingTop: theme.spacing(5),
});

const StyledImagePlaceholderIcon = styled(ImagePlaceholderIcon, {
  fill: theme.primaryBlue,
  padding: { x: theme.spacing(5) },
  maxHeight: '110px',
  margin: { x: 'auto' },
  ...media(mediaQuery.mediaMinMedium, {
    maxHeight: '180px',
  }),
  ...media(mediaQuery.mediaMinLarge, {
    maxHeight: '220px',
  }),
});

const PlanogramImage = styled.img({
  margin: { x: 'auto' },
  maxHeight: '110px',
  padding: { x: theme.spacing(5) },
  ...media(mediaQuery.mediaMinMedium, {
    maxHeight: '180px',
  }),
  ...media(mediaQuery.mediaMinLarge, {
    maxHeight: '220px',
  }),
});

const PlanogramText = styled.div({
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  lineHeight: '17px',
  textAlign: 'center',
  marginTop: theme.spacing(6),
  color: theme.black,
  padding: { x: theme.spacing(2) },
  ...media(mediaQuery.mediaMinLarge, {
    fontSize: theme.delta,
    lineHeight: '22px',
  }),
});

const PlanogramArticleNumber = styled.span({
  font: { size: theme.beta },
  textAlign: 'center',
  color: theme.breadText,
  marginTop: theme.spacing(3),
});

export default MyPlanogramsComponent;
