import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ImagePlaceholderIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M21 3H3C2 3 1 4 1 5v14c0 1.1.9 2 2 2h18c1 0 2-1 2-2V5c0-1-1-2-2-2zm0 15.92c-.02.03-.06.06-.08.08H3V5.08L3.08 5h17.83c.03.02.06.06.08.08v13.84zm-10-3.41L8.5 12.5 5 17h14l-4.5-6z" />
    </styled.Svg>
  );
}

export default styled(ImagePlaceholderIcon);
